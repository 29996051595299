<template>
  <div class="container">
<div class="topImg">
    <img src="https://web-static-res.bowu66.com/zhelihaowan/static/media/success.e322e6b2.png" alt="">
</div>
<div class="yuyueSuccess">预约成功</div>
<div class="notice">
   <div class="ddd"> 温馨提示：</div>
   <div> 请注意查看短信，届时携带身份证（儿童需携带户口本）入园</div>
   </div>
<div class="myOrder" @click="myHistory">我的历史订单 ></div>
  </div>
</template>

<script>
export default {
   data(){
return{
    
}
   },
   methods:{
        //我的历史订单
   myHistory(){
       this.$router.push({path:'/historyDetail'})
   },
   }
}
</script>

<style lang="less" scoped>
.container{
    overflow: scroll;
    padding: 0 30px;
    box-sizing: border-box;


    .topImg{
        width: 300px;
        height: 300px;
        box-sizing: border-box;
        margin: 0 auto;
        margin-top: 100px;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .yuyueSuccess{
        width: 100%;
        text-align: center;
        font-size: 40px;
        font-weight: bolder;
        margin:  0 auto;
    }
    .notice{
        margin-top: 50px;
        width: 100%;
        font-size: 24px;
        color: #EC624F;
        text-align: center;
        .ddd{
            width: 100%;
           padding-left: 20px;
            text-align: left;

        }
    }
    .myOrder{
        margin-top: 50px;
        width: 100%;
        text-align: center;
        color: #6492D7;
    }
}


</style>